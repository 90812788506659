<!--
File: Sections.vue
Description: show list of road sections entered in the DB.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination" :from="from" :to="to" :total="total"
    :btnAddAllowed="isBtnAllowed('AddButton')" :showCloseBtn="!!closePath" @add-item="viewItem()">

    <template #header-filters>
      <RegionsDropdown class="md-layout-item md-size-15" v-model="selectedRegion" />
      <DepsDropdown class="md-layout-item md-size-10" v-model="selectedDeu" :regionId="selectedRegion"
        :disabled="!selectedRegion" />
      <RoadsDropdown class="md-layout-item md-size-20" v-model="selectedRoad" :items="roadsList"
        :disabled="!selectedRegion" />
      <search-input class="md-layout-item md-size-15" v-model="searchQuery" />
    </template>

    <template #table-rows>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>

        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />

        <md-table-row slot="md-table-row" slot-scope="{item}"
          :class="{ blinking: item[idFieldName] === highlightedRow }"
          :id="item[idFieldName] === highlightedRow ? 'highlightedRow' : null">

          <md-table-cell v-for="cell in getColumns" :key="cell.key" :md-label="cell.header"
            :md-sort-by="cell.sortable ? cell.key : null" :md-numeric="cell.num">
            <span v-if="cell.num">{{ item[cell.key] | numFormat(cell.digits) }}</span>
            <span v-else>{{ item[cell.key] }}</span>
          </md-table-cell>

          <md-table-cell :md-label="$t('tables.actions')">
            <md-button class="md-just-icon md-success md-simple" :title="$t('buttons.details')"
              @click="showDetails(item.section_id)">
              <md-icon>preview</md-icon>
            </md-button>
            <table-actions :btnEditAllowed="isBtnAllowed('EditButton')" :btnDeleteAllowed="isBtnAllowed('DeleteButton')"
              @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.section_description)" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>

    <template #editing-dialog>
      <SectionDetails v-if="showSectionDetailsDlg" :sectionId="currentId" @close="showSectionDetailsDlg = false" />
      <EditForm v-if="showDetailsDlg" :itemId="currentId" @close="showDetailsDlg = false" />
    </template>
  </base-data-table>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { BaseDataTable, RegionsDropdown, DepsDropdown, RoadsDropdown, SearchInput, TableActions } from '@/pages/Components'
import BaseTableMixin from '@/mixins/BaseTableMixin'
import EditForm from './SectionEditForm.vue'
//import SectionDetails from './SectionDetails.vue'
import SectionDetails from './SectionEditOverview.vue'

export default {
  name: 'sections-list',
  mixins: [BaseTableMixin],

  data() {
    return {
      formName: 'Sections',
      idFieldName: 'section_id',

      showSectionDetailsDlg: false,
      selectedDeu: null,
      selectedRoad: null,
      deuLabel: '',

      currentSort: 'deu',
      propsToSearch: ['deu', 'road', 'section_description'],
      historyMapping: {
        selectedRegion: 0,
        selectedDeu: 0,
        selectedRoad: 0,
        currentSort: 'deu',
        currentSortOrder: 'asc',
        pagination: this.pagination,
      }
    }
  },

  components: {
    BaseDataTable,
    SearchInput,
    TableActions,
    RegionsDropdown,
    DepsDropdown,
    RoadsDropdown,
    EditForm,
    SectionDetails
  },

  async mounted() {
    this.deuLabel = this.$t('road_network.dep')
  },

  methods: {
    ...mapActions({
      clearList: 'CLEAR_SECTION_LIST',  // Called from the BaseTableMixin's mount()
      loadList: 'LOAD_SECTION_LIST',
      delete: 'DELETE_SECTION',
    }),

    showDetails(id) {
      this.currentId = id
      this.showSectionDetailsDlg = true
    },
  },

  computed: {
    ...mapState({
      highlightedRow: (state) => state.History.row,
      tblData: (state) => state.RoadNetwork.section_list,
    }),
    ...mapGetters(['roadsListFiltered', 'closePath']),

    tableData() {
      const updatedData = this.tblData.map(item => ({
        ...item,
        deu: `${this.deuLabel}-${item.deu}`
      }));
      return this.customSort(updatedData, 'start_distance_m');
    },

    roadsList() {
      return this.roadsListFiltered(this.selectedDeu)
    },

    getColumns() {
      return [
        { header: this.$t('road_network.dep'), key: 'deu', sortable: true, width: 5 },
        { header: this.$t('road_network.road_key'), key: 'road_key', sortable: true, width: 10, },
        { header: this.$t('stdCols.name'), key: 'section_description', sortable: true, width: 40 },
        { header: this.$t('inventory.start_distance_m'), key: 'start_distance_m', sortable: true, width: 10, num: true, digits: 0 },
        { header: this.$t('inventory.end_distance_m'), key: 'end_distance_m', sortable: true, width: 10, num: true, digits: 0 },
      ]
    },
  },

  watch: {
    async selectedRegion(newVal) {
      this.payload = { region_id: newVal };
      this.selectedDeu = null;
      if (!this.loadingHistory) await this.reloadData();
    },

    async selectedDeu(newVal) {
      this.payload.deu_id = newVal;
      this.selectedRoad = null;
      if (!this.loadingHistory) await this.reloadData();
    },

    async selectedRoad(newVal) {
      this.payload.road_id = newVal;
      if (!this.loadingHistory) await this.reloadData();
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>